<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab
          title="컨텐츠별 조회 유형"
          @click="viewType = 'groups'"
        />
        <b-tab
          title="컨텐츠별 조회 개수"
          @click="viewType = 'view-count'"
        />
      </b-tabs>
    </b-card>

    <b-card>
      <groups-view v-if="viewType === 'groups'" />
      <view-count-view v-else-if="viewType === 'view-count'" />
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import GroupsView from './view/GroupsView.vue'
import ViewCountView from './view/ViewCountView.vue'

export default {
  components: {
    BTab,
    BTabs,
    GroupsView,
    ViewCountView,
  },

  setup() {
    const viewType = ref("groups")

    return {
      viewType,
    }
  },
}
</script>
