var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-tabs",
            { attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { title: "컨텐츠별 조회 유형" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "groups"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "컨텐츠별 조회 개수" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "view-count"
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _vm.viewType === "groups"
            ? _c("groups-view")
            : _vm.viewType === "view-count"
            ? _c("view-count-view")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }